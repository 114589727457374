import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, SlotId, mappedAudioSprites } from '../config';
import {
  BetBonusReward,
  EventTypes,
  GameMode,
  ISettledBet,
  ReelSet,
  UserBonus,
  bonusesId,
  reelSets,
} from '../global.d';
import {
  client,
  getUserBonuses,
  isStoppedGql,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
  slotBetGql,
} from '../gql';
import { setBrokenPickem } from '../gql/cache';
import { ReelSetType } from '../gql/d';
import {
  formatNumber,
  getSpinResult,
  isBuyFeatureEnabled,
  isFreeSpinMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';

import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  FREE_SPINS_TIME_OUT_BANNER,
  REELS_AMOUNT,
  SlotMachineState,
  eventManager,
} from './config';
import { ISlotData, Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import { buttonPosition, titlePosition } from './messageBanner/config';
import { btnStyle, titleStyle } from './messageBanner/textStyles';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import Slot from './slot/slot';
import SpinAnimation from './spin/spin';
import StickyWildsContainer from './stickyWilds/stickyWildsContainer';
import TintContainer from './tint/tintContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotData, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public stickyWildsContainer: StickyWildsContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    application: PIXI.Application,
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    let { startPosition } = slotData.settings;
    let reelSet = slotData.reels.find((reel) => reel.type === ReelSetType.DEFAULT) as ReelSet;

    if (setUserLastBetResult().id) {
      startPosition = this.getLastBetReelPositions();
      reelSet = this.getReelSetForGameMode(slotData);
    }

    setReelSetId(reelSet.id);
    setPrevReelsPosition(startPosition.slice(0, 5));
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.miniPayTableContainer = new MiniPayTableContainer(slotData.icons, this.getSlotById.bind(this));
    this.stickyWildsContainer = new StickyWildsContainer();
    this.miniPayTableContainer.setSpinResult(
      getSpinResult({
        reelPositions: startPosition.slice(0, 5),
        reelSet,
        icons: slotData.icons,
      }),
    );
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    if (setBrokenGame()) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t('freeSpinsTitle'),
        spins: setCurrentBonus().rounds,
        currentSpin: setCurrentBonus().currentRound,
      });
    }
  }

  private getLastBetReelPositions(): number[] {
    // If last bet was PICKEM shot query we will have no postions so manually put [0, 0, 0, 0, 0] reels value for it
    return setUserLastBetResult().result.reelPositions.length
      ? setUserLastBetResult().result.reelPositions
      : [0, 0, 0, 0, 0];
  }

  private async onBrokenGame(): Promise<void> {
    const gameMode = this.getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    this.setState(SlotMachineState.IDLE);
  }

  private getReelSetForGameMode(slotData: ISlotData): ReelSet {
    if (setBrokenPickem()) {
      return slotData.reels.find((reelSet) => reelSet.id === reelSets[GameMode.REGULAR])!;
    }

    if (setBrokenGame()) {
      return slotData.reels.find((reelSet) => reelSet.id === reelSets[GameMode.FREE_SPINS])!;
    }

    return slotData.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!;
  }

  private getGameModeByBonusId(bonusId: string): GameMode {
    if (bonusId === bonusesId[GameMode.PICKEM]) return GameMode.PICKEM;
    if (bonusId === bonusesId[GameMode.FREE_SPINS]) return GameMode.FREE_SPINS;
    return GameMode.REGULAR;
  }

  private initPixiLayers() {
    this.application.stage.addChild(
      new Background(),
      new Backdrop(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, EventTypes.CLOSE_BUY_FEATURE_POPUP_BG),
      this.initSafeArea(),
      new BottomContainer(),
      new FadeArea(),
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      linesContainer: new LinesContainer(slotData.lines),
      reelsContainer: this.reelsContainer,
      tintContainer: new TintContainer(),
      winLabelContainer: new WinLabelContainer(),
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
      stickyWildsContainer: this.stickyWildsContainer,
    });

    gameView.interactive = true;
    gameView.on('mousedown', () => this.skipAnimations());
    gameView.on('touchstart', () => this.skipAnimations());

    if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
      this.initBuyFeature(slotData.lines, gameView);
    }

    return gameView;
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new Backdrop(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, EventTypes.CLOSE_BUY_FEATURE_POPUP_BG),
      new BuyFeaturePopup(lines),
      new BuyFeaturePopupConfirm(),
    );
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
      if (setBrokenBuyFeature()) {
        nextTick(() => {
          eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
          setBrokenBuyFeature(false);
        });
      }
      if (setBrokenGame()) {
        this.onBrokenGame();
      }
      if (setBrokenPickem()) {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
    });
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.END_RETRIGGER_FEATURE, this.onRetriggerEnd.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, this.startBuyFeature.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }) {
    setGameMode(settings.mode);
    setReelSetId(settings.reelSetId);

    const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);

    if (settings.mode !== GameMode.PICKEM) {
      const spinResult = getSpinResult({
        reelPositions: settings.reelPositions.slice(0, 5),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });

      this.miniPayTableContainer.setSpinResult(spinResult);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId),
        reelPositions: settings.reelPositions,
      });
    }
    setPrevReelsPosition(settings.reelPositions);
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonusId('');
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, false);
      this.setState(SlotMachineState.IDLE);

      // AudioHowl.stop({ type: ISongs.FreeSpinBGM_Intro });
      AudioHowl.stop({ type: ISongs.FreeSpinBGM_Loop });
      AudioHowl.play({ type: ISongs.BaseGameBGM_Base });
      AudioHowl.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });
    } else if (settings.mode === GameMode.PICKEM) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

      AudioHowl.stop({ type: ISongs.BaseGameBGM_Base });
      AudioHowl.stop({ type: ISongs.BaseGameBGM_Melo });

      AudioHowl.play({ type: ISongs.PickemBonusBGM_Loop });
    } else if (isFreeSpinMode(settings.mode)) {
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
      eventManager.emit(EventTypes.SET_WIN_LABEL_TEXT, 'total win');
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      setCurrentBonusId(setCurrentBonus().id);

      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t('freeSpinsTitle'),
        spins: setCurrentBonus().rounds,
        currentSpin: setCurrentBonus().currentRound,
      });

      this.setState(SlotMachineState.IDLE);

      // bgm
      // const introDelay = Tween.createDelayAnimation(
      //   mappedAudioSprites[ISongs.FreeSpinBGM_Intro].duration,
      // );
      // AudioHowl.stop({ type: ISongs.BaseGameBGM_Base });
      // AudioHowl.stop({ type: ISongs.BaseGameBGM_Melo });
      // AudioHowl.play({ type: ISongs.FreeSpinBGM_Intro });
      // introDelay.addOnComplete(() => {
      //   const { volume } = AudioHowl.getSoundByKey(ISongs.FreeSpinBGM_Intro);
      AudioHowl.stop({ type: ISongs.PickemBonusBGM_Loop });
      AudioHowl.play({ type: ISongs.FreeSpinBGM_Loop });
      // });
      // introDelay.start();
    } else if (settings.mode === GameMode.BUY_FEATURE) {
      // this.spin(false);
    }
  }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.BUY_FEATURE],
    });
  }

  private startPickem(): void {
    this.setState(SlotMachineState.PICKEM);
    setIsFreeSpinsWin(true);
    const handlePickemBanner = () => {
      AudioHowl.play({ type: ISongs.PickemBonusEntrancePopup, stopPrev: true });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        bannerBg: 'pickem_bonus_pop_up',
        title: {
          text: 'pickemMessageBannerText',
          position: titlePosition,
          styles: titleStyle,
        },
        btn: {
          text: 'pickemMessageBannerBtnText',
          position: buttonPosition,
          styles: btnStyle,
        },
      });
    };

    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.PICKEM,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.PICKEM],
      callback: handlePickemBanner,
    });
  }

  // todo implement start free spins
  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonusId() } },
      fetchPolicy: 'network-only',
    });
    const bonus = res.data.userBonuses[0];
    // todo replace with real backend logic
    const { betId } = res.data.userBonuses[0].data;
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });
    const { reelPositions, winCountAmount, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    };

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioHowl.play({ type: ISongs.FreeSpinExitPopup, stopPrev: true });
    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        reelPositions,
      });
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        bannerBg: 'free_spins_pop_up',
        title: {
          text: i18n.t('freeSpinsMessageBannerWinText', {
            winAmount: formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
          }),
          position: titlePosition,
          styles: titleStyle,
        },
        btn: {
          text: 'freeSpinsMessageBannerWinBtnText',
          position: buttonPosition,
          styles: btnStyle,
        },

        preventDefaultDestroy: true,
        callback,
      });
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        bannerBg: 'free_spins_pop_up',
        title: {
          text: i18n.t('freeSpinsMessageBannerWinText', {
            winAmount: formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
          }),
          position: titlePosition,
          styles: titleStyle,
        },
        btn: {
          text: 'freeSpinsMessageBannerWinBtnText',
          position: buttonPosition,
          styles: btnStyle,
        },

        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, current, total);
  }

  private dynamicReelSetChange(nextResult: ISettledBet): void {
    if (setReelSetId() !== this.nextResult?.bet.reelSetId) {
      setReelSetId(this.nextResult?.bet.reelSetId);

      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === setReelSetId()),
        reelPositions: this.nextResult?.bet.result.reelPositions,
      });
    }
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.isReadyForStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (isFreeSpinMode(setGameMode()))
          this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
        this.removeErrorHandler();
        this.dynamicReelSetChange(this.nextResult);
        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.bet.result.reelPositions,
          this.getScatterCount(this.nextResult.bet.result.spinResult),
          this.getAnticipationReelId(this.nextResult.bet.result.spinResult),
        );
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!isFreeSpinMode(setGameMode()) && !!isTurboSpin);

      if (isFreeSpinMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        setCurrentBonus(bonus);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const mode = setGameMode();
    const bonus = setCurrentBonus();
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      let spinAnimation: SpinAnimation;
      if (mode === GameMode.BUY_FEATURE) {
        spinAnimation = reel.createBuyFeatureSpinAnimation(isTurboSpin);
      } else {
        spinAnimation = reel.createSpinAnimation(isTurboSpin);
      }
      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
            this.dynamicReelSetChange(this.nextResult);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationReelId(this.nextResult.bet.result.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin));
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private isFreeSpins(): boolean {
    return !!(
      this.nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus;
  }

  private onCountUpEnd(): void {
    const mode = setGameMode();

    if (this.isFreeSpins()) {
      if (mode === GameMode.BUY_FEATURE || mode === GameMode.REGULAR) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentBonus({
          ...this.nextResult!.bet.data.bonuses[0],
          isActive: true,
          currentRound: 0,
        });
        setCurrentBonusId((this.nextResult?.rewards[1] as BetBonusReward).userBonusId);
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        this.startPickem();
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      }
      if (isFreeSpinMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
    }
    this.setState(SlotMachineState.IDLE);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
  }

  private onRetriggerEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        // eslint-disable-next-line no-plusplus
        if (spinResult[j].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

        if (currentCount >= count) minReelId = Math.min(minReelId, j);
      }
    });

    return minReelId + 1;
  }

  private getScatterCount(spinResult: Array<Icon>): Array<number> {
    let count = 0;

    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.SC1)) {
          count += 1;
          return count;
        }

        if (col.some((icon) => icon.id === SlotId.WL)) {
          return 4; // 4 is for WILD
        }

        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, 5));
    this.nextResult = result;
    if (!isFreeSpinMode(setGameMode())) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.placed);
    }
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    this.stickyWildsContainer.setSpinResult(this.nextResult!.bet.data.features.multipliers);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length - this.reelsContainer.reels[x].position + y - 1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinMode(setGameMode())) {
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.isFreeSpins() && !isFreeSpinMode(setGameMode())) {
        const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.FeatureTrigger].duration);
        jingleDelay.addOnStart(() => {
          AudioHowl.play({ type: ISongs.FeatureTrigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    }
  }
}

export default SlotMachine;
