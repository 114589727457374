import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Popup } from '@phoenix7dev/shared-components/';
import { cashierUrlAvailable, goToCashier } from '@phoenix7dev/utils-fe';

import {
  setCurrentBonus,
  setIsBuyFeaturePurchased,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setProgress,
  setStressful,
  stressfulGql,
} from '../../gql';
import { IStressful } from '../../gql/d';
import { queryParams } from '../../utils';

import styles from './stressful.module.scss';

const defaultStressful: IStressful = {
  show: false,
  type: 'none',
  message: '',
};

const getMessageTypeError = (t: TFunction<'translation'>, message: string) => {
  if (message) {
    return message;
  }
  return t('errors.UNKNOWN.UNKNOWN');
};

const Stressful: React.FC = () => {
  const { data } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { t } = useTranslation();

  const bonusGame = useReactiveVar(setCurrentBonus);
  const isTimeoutError = useReactiveVar(setIsTimeoutErrorMessage);

  const btn = React.useMemo(() => {
    if (data?.stressful.type === 'balance') {
      return (
        cashierUrlAvailable(queryParams) && (
          <button className={`${styles.btn} btn`} onClick={() => goToCashier(queryParams)}>
            {t('cashier')}
          </button>
        )
      );
    }
    return null;
  }, [data?.stressful, t]);

  if (!data) {
    return null;
  }

  const { type, message } = data.stressful;

  return (
    <div className={data.stressful.show ? styles.backdrop : ''}>
      <Popup id="stressful" className="popup" showNow={data.stressful.show} setShowNow={() => null}>
        <div className={styles.stressful}>
          <div className={styles.center}>
            <p className={styles.title}>{getMessageTypeError(t, message)}</p>
            <div className={styles.flex}>
              {btn}
              {!bonusGame.isActive && setProgress().wasLoaded && !isTimeoutError && (
                <button
                  className={`${styles.btn} btn`}
                  onClick={() => {
                    setStressful(defaultStressful);
                    setIsRevokeThrowingError(false);
                    setIsBuyFeaturePurchased(false);
                  }}
                >
                  {t('close')}
                </button>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Stressful;
